import Vue from "vue";

/*TITLE*/
document.title = "Residencial Zigurat | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Zigurat";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Zigurat";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091754.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091754.jpeg";
Vue.prototype.$image_bath1 = "b1_forest-natural_143x90_22x90-vela-grey_316x90-limit-vela-black_316x90--20230126050108.jpg";
Vue.prototype.$image_bath2 = "b2_forest-maple_143x90_22x90-urban-natural-nature_316x90-cubik-urban-natural-nature_316x90--20230126050126.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "zg01-ac.tif--20230126050102.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091754.jpeg",
  },
  {
    src: "1-livingroom--20221124091754.jpeg",
  },
  {
    src: "salon_forest-maple_143x90_22x90--20230126050128.jpg",
  },
  {
    src: "salon_forest-natural_143x90_22x90--20230126050134.jpg",
  },
  {
    src: "salon_forest-acero_143x90_22x90--20230126050141.jpg",
  },
  {
    src: "salon_forest-cognac_143x90_22x90--20230126050148.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091754.jpeg",
  },
  {
    src: "1-kitchen--20221124091754.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "b1_forest-natural_143x90_22x90-vela-grey_316x90-limit-vela-black_316x90--20230126050108.jpg",
  },
  {
    src: "b1_forest-maple_143x90_22x90-vela-smoked_316x90-limit-vela-smoked_316x90--20230126050113.jpg",
  },
  {
    src: "b1_forest-acero_143x90_22x90-vela-grey_316x90-limit-vela-black_316x90--20230126050120.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "b2_forest-maple_143x90_22x90-urban-natural-nature_316x90-cubik-urban-natural-nature_316x90--20230126050126.jpg",
  },
  {
    src: "b2_forest-natural_143x90_22x90-urban-black_316x90-cubik-urban-black_316x90--20230126050135.jpg",
  },
  {
    src: "b2_forest-acero_143x90_22x90-urban-black_316x90-cubik-urban-black_316x90--20230126050142.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "zg01-ac.tif--20230126050102.jpg",
  },
  {
    src: "zg02-ac.tif--20230126050108.jpg",
  },
  {
    src: "01_jardin--20230126050115.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20mustienes%20luesma%2017%2050011%20zaragoza",
    text: "C/ Mustienes Luesma 17, 50011, Zaragoza",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20andrés%20vicente%2010%20local%20comercial%2050017%20zaragoza",
    text: "C/Andrés Vicente 10, local comercial, 50017, Zaragoza",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:876645838",
    text: "876645838",
  },
  {
    icon: "mdi-email",
    link: "mailto:comercial@fincasrillo.com",
    text: "comercial@fincasrillo.com",
  },
];
